<template>
  <div>
    <h4 class="mb-1">
      {{ $t('organization.presentation.title') }}
    </h4>
    <b-form v-if="!organizationPresentationLoading" @submit.prevent="validationForm">
      <b-row class="mb-1">
        <!-- Media AVATAR-->
        <b-col lg="12" xl="4">
          <label class="mt-1" label-for="avatar-file">{{ $t('organization.presentation.logo.upload') }}</label>
          <div v-if="canEdit">
            <div class="text-muted font-small-3">
              <span class="font-weight-bold">{{ $t('dropzone.accepted_format') }}:</span> <span>png, jpeg</span> |
              <span class="font-weight-bold">{{ $t('dropzone.max_file_size') }}:</span> <span>2 Mo</span>
            </div>

            <app-drop-zone
              id="organizationPresentationLogo"
              ref="organizationPresentationLogo"
              :options="dropzoneLogo"
              class="d-flex justify-content-center align-content-center dropzone_small"
              @vdropzone-mounted="dropzoneMounted(organizationPresentation.logoFileUrl, 'organizationPresentationLogo', 0, 'filesUploaded')"
              @vdropzone-removed-file="organizationPresentation.deleteOldLogoFile = true"
              @vdropzone-file-added="sendingFile($event, organizationPresentation, 'logoFileId', 'filesUploaded', 'deleteOldLogoFile', null, 'organizationPresentationLogo')"
            />
          </div>
          <div v-else style="width: 300px; height: 300px">
            <img :src="organizationPresentation.logoFileUrl" style="width: 100%; height: 100%; object-fit: contain">
          </div>
        </b-col>

        <!--Banner-->
        <b-col v-if="seller" lg="12" xl="8">
          <label class="mt-1" label-for="banner-file">{{ $t('organization.presentation.banner.upload') }}</label>
          <div v-if="canEdit">
            <div class="text-muted font-small-3">
              <span class="font-weight-bold">{{ $t('dropzone.accepted_format') }}:</span> <span>png, jpeg</span> |
              <span class="font-weight-bold">{{ $t('dropzone.max_file_size') }}:</span> <span>2 Mo</span>
            </div>
            <vue-dropzone
              :disabled="!canEdit"
              id="organizationPresentationBanner"
              ref="organizationPresentationBanner"
              :options="dropzoneBanner"
              class="d-flex justify-content-center align-content-center dropzone_small"
              @vdropzone-mounted="dropzoneMounted(organizationPresentation.bannerFileUrl, 'organizationPresentationBanner', 0, 'filesUploaded', 'banner')"
              @vdropzone-removed-file="organizationPresentation.deleteOldBannerFile = true"
              @vdropzone-file-added="sendingFile($event, organizationPresentation, 'bannerFileId', 'filesUploaded', 'deleteOldBannerFile', null, 'organizationPresentationBanner')"
            />
          </div>
          <div v-else style="width: 300px; height: 300px">
            <img :src="organizationPresentation.bannerFileUrl" style="width: 100%; height: 100%; object-fit: contain">
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col cols="12">
          <div class="d-flex">
            <h4 class="mb-1">
              {{ $t('organization.presentation.description') }}
            </h4>
          </div>
          <quill-editor
            :disabled="!canEdit" v-if="organizationPresentation" v-model="organizationPresentation.description" :options="quillEditorOptions" />
        </b-col>
      </b-row>

      <b-row v-show="seller">
        <b-col cols="12">
          <h4 class="mb-1">
            {{ $t('organization.presentation.images') }}
          </h4>
          <div v-if="canEdit">
            <div class="text-muted font-small-3">
                <span class="font-weight-bold">{{ $t('dropzone.accepted_format') }}:</span> <span>png, jpeg</span> |
                <span class="font-weight-bold">{{ $t('dropzone.max_file_size') }}:</span> <span>2 Mo</span>
            </div>
            <!-- DROPZONE -->
            <b-form-group>
              <b-overlay :show="dropZoneBannersLoading" spinner-medium class="mt-1">
                <app-drop-zone
                  :disabled="!canEdit"
                  id="banners"
                  ref="banners"
                  :options="dropzoneBannersOptions"
                  @vdropzone-queue-complete="iconUploaded = true"
                  @vdropzone-removed-file="removePlainFile($event)"
                  @vdropzone-file-added="sendPlainFile($event)"
                />
              </b-overlay>
            </b-form-group>
          </div>
          <div v-else>
            <b-row>
              <b-col v-for="(banner, index) in organizationPresentation.banners" :key="index" cols="2" style="height: 150px;">
                <img :src="banner.bannerUrl" style="width: 100%; height: 100%; object-fit: contain">
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="seller" class="mt-1">
        <b-col cols="12">
          <b-card class="border">
            <b-card-title>
              {{ $t('provider.facilities_assets') }}
            </b-card-title>
            <h6>{{ $t('common.aircraft') }}</h6>

            <enum-checkbox
              :disabled="!canEdit"
              id="aircraftFacilities"
              :value="organizationPresentation.aircraftFacilities"
              enum-class="AircraftFacilityValue"
              label=""
              text="text"
              text-field="text"
              value-field="value"
              @change="organizationPresentation.aircraftFacilities = $event"
            />

            <h6>{{ $t('common.passenger') }}</h6>
            <enum-checkbox
              :disabled="!canEdit"
              id="passengerFacilities"
              v-model="organizationPresentation.passengerFacilities"
              enum-class="passengerFacilityValue"
              label=""
              text="text"
              @change="organizationPresentation.passengerFacilities = $event"
            />

            <h6>{{ $t('common.crew') }}</h6>
            <enum-checkbox
              :disabled="!canEdit"
              id="crewFacilities"
              v-model="organizationPresentation.crewFacilities"
              enum-class="CrewFacilityValue"
              label=""
              text="text"
              @change="organizationPresentation.crewFacilities = $event"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="seller">
        <b-col cols="12">
          <b-card>
            <b-card-title>
              {{ $t('provider.working_hours') }}
            </b-card-title>
            <b-row class="align-items-center font-weight-bold bg-light-primary border-light rounded">
              <b-col class="py-1 bg-light-primary" cols="2">
                <p>{{ $t('common.day') }}</p>
              </b-col>
              <b-col class="align-items-center border-right-light" cols="10" sm="5">
                <p>{{ $t('common.morning') }}</p>
                <p class="d-block d-sm-none">{{ $t('common.afternoon') }}</p>
              </b-col>
              <b-col class="d-none d-sm-block align-items-center border-right-light" sm="5">
                <p>{{ $t('common.afternoon') }}</p>
              </b-col>
            </b-row>
            <working-day :disabled="!canEdit" :day-name="$t('common.monday')" :working-day="organizationPresentation.monday" />
            <working-day :disabled="!canEdit" :day-name="$t('common.tuesday')" :working-day="organizationPresentation.tuesday" />
            <working-day :disabled="!canEdit" :day-name="$t('common.wednesday')" :working-day="organizationPresentation.wednesday" />
            <working-day :disabled="!canEdit" :day-name="$t('common.thursday')" :working-day="organizationPresentation.thursday" />
            <working-day :disabled="!canEdit" :day-name="$t('common.friday')" :working-day="organizationPresentation.friday" />
            <working-day :disabled="!canEdit" :day-name="$t('common.saturday')" :working-day="organizationPresentation.saturday" />
            <working-day :disabled="!canEdit" :day-name="$t('common.sunday')" :working-day="organizationPresentation.sunday" />
          </b-card>
        </b-col>
      </b-row>

      <app-form-wizard-footer :disabled="!canEdit" class="mt-3" previous save save-next @click:previous="$emit('previous-tab')" @click:save-next="nextRedirection = true" />

    </b-form>

  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/no-extraneous-dependencies
import Quill from 'quill'
import { mapState } from 'vuex'
import ImageCompress from 'quill-image-compress'

import EnumCheckbox from '@/components/EnumCheckbox.vue'
import imageUpload from '@/helpers/imgUpload'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {
  fetchOrganizationPresentationRequest,
  patchOrganizationPresentationRequest,
} from '@/request/globalApi/requests/organizationRequests'
import AppDropZone from '@/components/AppDropZone.vue'
import WorkingDay from '@/views/organization/tab/WorkingDay.vue'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'
import { differenceWith, isEqual, remove } from 'lodash'
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'

export default {
  name: 'OrganizationPresentation',
  components: {
    AppDropZone,
    EnumCheckbox,
    quillEditor,
    vueDropzone: AppDropZone,
    WorkingDay,
    AppFormWizardFooter,
  },

  mixins: [imageUpload],
  props: {
    organizationId: {
      type: [String, Number],
      required: true,
      default: '',
    },
    operator: {
      type: Boolean,
      default: false,
    },
    seller: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filesUploaded: true,
      organizationPresentation: {},
      organizationPresentationLoading: true,
      nextRedirection: false,
      editor: {
        description: {
          currentLanguage: null,
        },
      },
      dropzoneLogo: {
        autoProcessQueue: true,
        url: 'https://localhost',
        dictDefaultMessage: this.$t('organization.presentation.logo.upload'),
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFiles: 1,
        maxFilesize: 2000000,
        acceptedFiles: 'image/jpeg,image/png',
        addRemoveLinks: true,
        clickable: true,
        init() {
          this.hiddenFileInput.removeAttribute('multiple')
          this.on('addedfile', function () {
            if (this.files.length > 1) {
              this.removeFile(this.files[0])
            }
          })
        },
      },
      dropzoneBanner: {
        autoProcessQueue: true,
        url: 'https://localhost',
        dictDefaultMessage: this.$t('organization.presentation.banner.upload'),
        thumbnailWidth: 300,
        thumbnailHeight: 150,
        maxFiles: 1,
        maxFilesize: 2000000,
        acceptedFiles: 'image/jpeg,image/png',
        addRemoveLinks: true,
        clickable: true,
        init() {
          this.hiddenFileInput.removeAttribute('multiple')
          this.on('addedfile', function () {
            if (this.files.length > 1) {
              this.removeFile(this.files[0])
            }
          })
        },
      },
      dropZoneBannersLoading: false,
      iconUploaded: false,
      dropzoneBannersOptions: {
        autoProcessQueue: false,
        url: 'https://localhost',
        dictDefaultMessage: this.$t('dropzone.drop_here'),
        thumbnailWidth: 250,
        maxFilesize: 2000000,
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        clickable: true,
      },
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    quillEditorOptions() {
      return {
        placeholder: this.label || this.$t('common.description'),
        theme: 'snow',
        modules: {
          toolbar: [
            'bold',
            'italic',
            'underline',
            'blockquote',
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
            { direction: 'rtl' },
            { size: ['small', false, 'large', 'huge'] },
            { color: [] },
            { background: [] },
            { align: [] },
            'link',
            'image',
            'video',
          ],
          imageCompress: {
            quality: 0.7,
            maxWidth: 500,
            maxHeight: 500,
            imageType: 'image/jpeg',
            debug: false,
            suppressErrorLogging: false,
            insertIntoEditor: undefined,
          },
        },
      }
    },
  },
  watch: {
    filesUploaded(val) {
      this.$emit('onFilesUploaded', val)
    },
  },

  beforeCreate() {
    Quill.register('modules/imageCompress', ImageCompress)
  },

  mounted() {
    this.fetchOrganizationPresentation()
  },
  methods: {
    fetchOrganizationPresentation() {
      fetchOrganizationPresentationRequest(this.organizationId).then(response => {
        const { organization } = response.data
        this.organizationPresentation = organization
        this.organizationPresentation.aircraftFacilities = this.organizationPresentation.aircraftFacilities.map(el => el.aircraftFacilityValue)
        this.organizationPresentation.crewFacilities = this.organizationPresentation.crewFacilities.map(el => el.crewFacilityValue)
        this.organizationPresentation.passengerFacilities = this.organizationPresentation.passengerFacilities.map(el => el.passengerFacilityValue)
      }).finally(() => {
        this.organizationPresentationLoading = false
        this.$nextTick(() => {
          if (this.organizationPresentation.banners) {
            this.organizationPresentation.banners.forEach(val => {
              const file = {
                id: val.id,
                size: 2000000,
                name: val.bannerPath,
              }

              this.$refs.banners.manuallyAddFile(file, val.bannerUrl)
            })

            this.organizationPresentation.banners = []
          }
        })
      })
    },
    removePlainFile(file) {
      if (!this.organizationPresentation.deletedBannerIds) {
        this.organizationPresentation.deletedBannerIds = []
      }

      this.organizationPresentation.deletedBannerIds.push(file.id)
    },
    sendPlainFile(file) {
      if (file.size > this.dropzoneBannersOptions.maxFilesize) {
        this.toaster(this.$t('file.max_file_size_reached'), 'danger', 'AlertTriangleIcon')
        this.$refs.banners.removeFile(file)
        return
      }

      this.dropZoneBannersLoading = true
      this.iconUploaded = false

      const formData = new FormData()
      formData.append('temporary_file', file)

      postFileRequest(formData)
        .then(response => {
          this.organizationPresentation.banners.push({
            id: response.data.id,
          })
        })
        .finally(() => {
          this.iconUploaded = true
          this.dropZoneBannersLoading = false
        })
    },
    validationForm() {
      const presentation = this.restructurePresentation()

      return patchOrganizationPresentationRequest(this.organizationId, presentation)
        .then(() => {
          if (this.nextRedirection) {
            this.$emit('next-tab')
            this.nextRedirection = false
          } else {
            this.$refs.banners.removeAllFiles()
            this.fetchOrganizationPresentation()
          }

          return true
        })
        .catch(() => false)
    },
    restructurePresentation() {
      const presentation = JSON.parse(JSON.stringify(this.organizationPresentation))
      const aircraftFacilities = []
      presentation.aircraftFacilities.forEach(facilities => {
        aircraftFacilities.push({ aircraftFacilityValue: facilities })
      })
      const crewFacilities = []
      presentation.crewFacilities.forEach(facilities => {
        crewFacilities.push({ crewFacilityValue: facilities })
      })
      const passengerFacilities = []
      presentation.passengerFacilities.forEach(facilities => {
        passengerFacilities.push({ passengerFacilityValue: facilities })
      })
      presentation.passengerFacilities = passengerFacilities
      presentation.crewFacilities = crewFacilities
      presentation.aircraftFacilities = aircraftFacilities
      return presentation
    },
  },
}
</script>

<style lang="scss">
.nav.nav-tabs {
  margin-bottom: 0;
}

.ql-container.ql-snow {
  min-height: 200px;
}
</style>
