<template>
  <div id="userList">
    <sidebar-invite-user v-model="isInviteUserSidebarActive" :organization-id="organizationId" @refetch-data="refetchData" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0 box-shadow-0">

      <!-- Table Top -->
      <div class="mb-1 d-flex justify-content-between align-items-center">
        <!-- Per Page -->
        <div>
          <v-select
            v-model="params.numberOfItemsPerPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mr-50"
          />
          <label>{{ $tc('pagination.entries', params.numberOfItemsPerPage) }}</label>
        </div>
        <div>
          <b-button v-if="$can('ORGANIZATION_USER_EDIT')" @click="isInviteUserSidebarActive = true">
            <font-awesome-icon icon="user-plus" class="mx-50" />
            {{ $t('action.add') }}
          </b-button>
        </div>
      </div>

      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <enum-select id="organizationUserStatus" v-model="params.organizationUserStatus" enum-class="OrganizationUserStatus" :label="$t('user.status')" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <app-input
            id="organizationUser-email"
            v-model="params.email"
            :label="$t('user.email')"
            debounce="500"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <app-input
            id="organizationUser-workrole"
            v-model="params.workrole"
            :label="$t('user.workrole')"
            debounce="500"
          />
        </b-col>
        <!-- FullName -->
        <b-col cols="12" md="3">
          <app-input
            id="organizationUser-fullName"
            v-model="params.fullName"
            :label="$t('user.full_name')"
            debounce="500"
          />
        </b-col>
      </b-row>

      <!--LIST-->
      <b-overlay :show="loadOrganizationUsers" no-fade>
        <b-table
          ref="refUserListTable"
          class="position-relative table-dropdown-action"
          :items="organizationUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          :empty-text="$t('common.no_record_found')"
        >
          <!-- Column: Role -->
          <template #cell(workrole)="data">
            <div class="text-nowrap">
              <font-awesome-icon icon="user" class="mr-50" :class="`text-${resolveRoleVariant(data.item.workrole)}`" />
              {{ data.item.workrole }}
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge pill :variant="resolveClassStatusOrganizationUser(data.item.status)" class="text-capitalize">
              {{ data.item.status|enumTranslate('OrganizationUserStatus') }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item v-if="$can('ORGANIZATION_USER_VIEW')" :to="{ name: 'user-organization-details', params: { organization_id: organizationId, organizationUser_id: data.item.id } }">
                <feather-icon icon="EyeIcon" />
                <span class="align-middle ml-50">{{ $t('action.show') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.id !== selectedOrganization.userId && $can('ORGANIZATION_USER_EDIT')"
                :to="{ name: 'user-organization-edit', params: { organization_id: organizationId, organizationUser_id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('action.edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="data.item.id !== selectedOrganization.userId && $can('ORGANIZATION_USER_DELETE')" @click="confirmDeleteUser(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('action.delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }} {{ dataMeta.of }}
              {{ $tc('pagination.entries', dataMeta.of) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="params.page"
              :total-rows="totalOrganizationUsers"
              :per-page="params.numberOfItemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <app-form-wizard-footer class="mt-3" previous next @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')" />

    </b-card>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import UIHelper from '@/helpers/ui'
import {
  deleteOrganizationUserRequest,
  fetchOrganizationUsersRequest,
} from '@/request/globalApi/requests/organizationRequests'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'
import SidebarInviteUser from './sidebar/SidebarInviteUser.vue'

export default {
  name: 'UserList',
  components: {
    SidebarInviteUser,
    AppFormWizardFooter,
  },
  mixins: [UIHelper],
  props: {
    organizationId: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'workrole', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions', thClass: 'max-width-actions', tdClass: 'max-width-actions' },
      ],
      loadOrganizationUsers: false,
      isInviteUserSidebarActive: false,
      organizationUsers: null,
      totalOrganizationUsers: 0,
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SORTING
        fullNameOrder: null,
        emailOrder: null,
        workroleOrder: null,
        statusOrder: null,
        // SEARCH
        fullName: null,
        email: null,
        workrole: null,
        organizationUserStatus: null,
      },
      perPageOptions: [10, 25, 50, 100],
      sortBy: null,
      sortDesc: null,
    }
  },
  computed: {
    ...mapState('appConfig', ['lang']),
    ...mapState('auth', ['selectedOrganization']),
    dataMeta() {
      const localItemsCount = this.$refs.refUserListTable ? this.$refs.refUserListTable.localItems.length : 0
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalOrganizationUsers,
      }
    },
    allFilters() {
      return `
       ${this.params.numberOfItemsPerPage}|${this.params.page}
      |${this.params.fullNameOrder}|${this.params.emailOrder}|${this.params.workroleOrder}|${this.params.statusOrder}
      |${this.params.fullName}|${this.params.email}|${this.params.workrole}|${this.params.organizationUserStatus}`
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchOrganizationUsers()
  },
  methods: {
    APIFetchOrganizationUsers() {
      if (this.sortBy) {
        switch (this.sortBy) {
          case 'name':
            this.params.fullNameOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'email':
            this.params.emailOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'workrole':
            this.params.workroleOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'status':
            this.params.workroleStatus = this.sortDesc ? 'asc' : 'desc'
            break
          default:
            this.params.fullNameOrder = 'asc'
        }
      }
      this.loadOrganizationUsers = true
      fetchOrganizationUsersRequest(this.organizationId, this.params).then(response => {
        const { organizationUsers, totalItems } = response.data
        this.organizationUsers = organizationUsers
        this.totalOrganizationUsers = totalItems
        this.loadOrganizationUsers = false
      })
    },
    APIDeleteOrganizationUser(organizationUserId, user) {
      const payload = {
        organizationId: this.organizationId,
        organizationUserId,
        message: user.name,
      }
      deleteOrganizationUserRequest(payload).then(() => {
        this.refetchData()
      })
    },
    confirmDeleteUser(user) {
      deleteOrganizationUserRequest(this.organizationId, user.id, user.name).then(response => {
        response && this.refetchData()
      })
    },
    refetchData() {
      this.APIFetchOrganizationUsers()
      this.$refs.refUserListTable.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.actionCol {
  width: 14%;
}
.per-page-selector {
  width: 90px;
}
</style>
<style lang ="scss">
#userList {
  .max-width-actions {
    max-width: 130px;
    width: 130px;
    text-align: center;
  }
}
</style>
