<template>
  <div class="d-lg-flex flex-lg-row">
    <b-nav id="navbarTab" :tabs="$store.getters['app/smAndDown']" :vertical="$store.getters['app/mdAndUp']">
      <b-nav-item
        v-for="(tab, index) in filteredTabs"
        :key="tab.name"
        :active="activeTabIndex === index"
        href="javascript:void(0)"
      >
        <div class="text-nowrap" @click="switchTab(tab.component)">
          <b-button :variant="tab.component === activeTab ? 'primary' : ''" class="nav-btn">
            <span>{{ index + 1 }}</span>
          </b-button>
          <span class="step-title">{{ $t(tab.title) }}</span>
        </div>
      </b-nav-item>
    </b-nav>

    <b-card class="d-inline-flex w-100 mb-0 p-2" no-body>
      <keep-alive>
        <component
          :is="activeTab"
          :key="key"
          ref="tabs"
          :operator="organization ? organization.operator : false"
          :organization-data.sync="organizationData"
          :organization-id="organizationId"
          :seller="organization ? organization.seller : false"
          :can-edit="canEdit"
          @onFilesUploaded="val => (saveLoader = !val)"
          @previous-tab="switchTab(filteredTabs[activeTabIndex - 1].component)"
          @next-tab="switchTab($event || filteredTabs[activeTabIndex + 1].component)"
        />
      </keep-alive>
    </b-card>
  </div>
</template>

<script>
import { findIndex } from 'lodash'
import { mapState } from 'vuex'

import usersList from '@/views/user/UserList.vue'
import Airport from '@/views/organization/tab/Airport.vue'
import { fetchOrganizationInfosRequest } from '@/request/globalApi/requests/organizationRequests'
import MainInformation from './tab/Information.vue'
import Presentation from './tab/Presentation.vue'
import LegalInformation from './tab/Legal.vue'
import KYC from './tab/KYC.vue'
import Accounting from './tab/Accounting.vue'
import Addresses from './tab/Addresses.vue'
import Offer from './tab/Offer.vue'
import BankAccounts from './tab/BankAccounts.vue'

export default {
  name: 'OrganizationWizard',

  components: {
    MainInformation,
    Presentation,
    LegalInformation,
    KYC,
    Accounting,
    Addresses,
    Airport,
    usersList,
    Offer,
    BankAccounts,
  },
  data() {
    return {
      canEdit: false,
      loading: true,
      saveLoader: false,
      currentTab: null,
      organization: null,
      activeTabIndex: 0,
      key: 0,
      tabs: [
        {
          title: this.$t('organization.main_informations.title'),
          component: 'main-information',
          ability: ['all'],
          additionalInfo: { title: 'mainInformation', sellerOnly: false },
        },
        {
          title: this.$t('organization.presentation.title'),
          component: 'presentation',
          ability: ['all'],
          additionalInfo: { title: 'presentation', sellerOnly: true, operatorOnly: true },
        },
        {
          title: this.$t('organization.legal_informations.title'),
          component: 'legal-information',
          ability: ['all'],
          additionalInfo: { title: 'legalInformation', sellerOnly: false },
        },
        {
          title: this.$t('organization.accounting.title'),
          component: 'accounting',
          ability: ['ACCOUNTING_VIEW'],
          additionalInfo: { title: 'Accounting', sellerOnly: true, operatorOnly: true },
        },
        {
          title: this.$t('organization.addresses'),
          component: 'addresses',
          ability: ['all'],
          additionalInfo: { title: 'addresses', sellerOnly: false },
        },
        {
          title: this.$t('organization.airports'),
          component: 'airport',
          ability: ['all'],
          additionalInfo: { title: 'airport', sellerOnly: true },
        },
        {
          title: this.$t('organization.users'),
          component: 'users-list',
          ability: ['ORGANIZATION_USER_VIEW'],
          additionalInfo: { title: 'usersList', sellerOnly: false },
        },
        {
          title: this.$t('organization.offer.offer'),
          component: 'offer',
          ability: ['all'],
          additionalInfo: { title: 'offer', sellerOnly: false },
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    filteredTabs() {
      const tabs = []
      this.tabs.forEach(tab => {
        if (
          (!tab.additionalInfo.sellerOnly && !tab.additionalInfo.operatorOnly)
            || (tab.additionalInfo.sellerOnly && this.organization && this.organization.seller)
            || (tab.additionalInfo.operatorOnly && this.organization && this.organization.operator)
        ) {
          const isGranted = !!this.$store.state.auth.abilities.find(ability => tab.ability.includes(ability.action))
          if (isGranted || tab.ability.includes(this.ability) || tab.ability.includes('all')) {
            // ! tab never hasOwnProperty('settingService')
            if (tab.hasOwnProperty('settingService')) {
              if (tab.settingService.includes(this.settingService)) {
                tabs.push(tab)
              }
            } else {
              tabs.push(tab)
            }
          }
        }
      })
      return tabs
    },
    organizationData() {
      return this.organization
    },
    organizationId() {
      return this.$route.name === 'editOrganization' ? String(this.$route.params.id) : String(this.selectedOrganization.id)
    },
  },
  watch: {
    '$route.params.tab': {
      handler(tabName) {
        this.activeTab = tabName || 'main-information'
        this.setActiveTabIndex()
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchOrganization()
    this.canEdit = this.$can('ORGANIZATION_ACCOUNT_EDIT') || this.$can('OPERATOR_ORGANIZATION_EDIT')
  },
  methods: {
    checkTabForm() {
      if (this.activeTab.validationForm !== undefined) {
        this.activeTab.validationForm.call().then(response => {
          if (!response) {
            this.toaster(this.$t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
          }
        })
      }
      return true
    },
    switchTab(componentName) {
      const query = {}
      const params = { tab: componentName }

      if (this.$route.name === 'editOrganization') {
        params.id = this.$route.params.id
      }

      if (this.activeTab !== params.tab) {
        this.$router.push({
          name: this.$route.name === 'editOrganization' ? 'editOrganization' : 'organization',
          params,
          query,
        })
      }
    },
    fetchOrganization() {
      return fetchOrganizationInfosRequest(this.organizationId).then(response => {
        this.organization = response.data.organization
        this.loading = false
        this.setActiveTabIndex()
      })
    },
    setActiveTabIndex() {
      const activeTabIndex = findIndex(this.filteredTabs, tab => tab.component === this.activeTab)

      this.key += 1
      if (activeTabIndex !== -1) this.activeTabIndex = activeTabIndex
    },
  },
}
</script>

<style lang="scss">
#navbarTab {
  .nav-item {
    padding: 8px;

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;

      .nav-btn {
        border: none;
        margin-right: 1rem;
        border-radius: 6px;
        width: 2.71rem;
        height: 2.71rem;
      }
    }

    .step-title {
      font-size: 1rem;
      font-weight: 600;
      color: #b8c2cc !important;
    }

    .nav-link.active .step-title {
      color: rgb(220, 193, 129) !important;
    }
  }
}

[dir] body.dark-layout #navbarTab {
  .nav-item {
    border-color: #3b4253 !important;

    .nav-link {
      .btn-secondary {
        background-color: rgba(220, 193, 129, 0.08) !important;
      }
    }
  }
}
</style>
