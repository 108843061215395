<template>
  <b-sidebar id="sidebar-submit-offer" no-header :visible="isSubmitOfferSidebarActive" @change="val => $emit('update:is-submit-offer-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('organization.offer.submit_offer') }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="submitOffers">
        <b-form class="p-1" @submit.prevent="handleSubmit(onSubmit)">
          <!-- Header: Offers -->
          <b-row>
            <b-col cols="12">
              <h4>{{ $t('organization.offer.offers') }}</h4>

              <app-select
                id="submission-offers"
                v-model="selectedOffers"
                :options="allOffers"
                :reduce="offer => offer.id"
                label="name"
                :overlay="offersLoading"
                multiple
                required
                :placeholder="$t('organization.offer.select_offer')"
                searchable
                :filter="filterOffers"
              >
                <template #option="{ reference }">
                  {{ reference | trans }}
                </template>
                <template #selected-option="{ reference }">
                  {{ reference | trans }}
                </template>
                <template #no-options> {{ $t('common.no_option_found') }} </template>
              </app-select>
            </b-col>
          </b-row>

          <!-- FOOTER -->
          <div class="d-flex mt-2">
            <b-button class="mr-2" type="submit">
              <font-awesome-icon icon="plus" class="mr-50" />
              {{ $t('action.submit') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hide">
              <font-awesome-icon icon="ban" class="mr-50" />
              {{ $t('action.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import { mapState } from 'vuex'
import { fetchOffersRequest } from '@/request/globalApi/requests/offerRequests'
import { patchOrganizationOfferRequest } from '@/request/globalApi/requests/organizationRequests'
import AppSelect from '@/components/AppSelect.vue'

export default {
  components: { AppSelect },
  props: {
    isSubmitOfferSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectOffersParams: {
      type: Object,
      default: () => ({
        getAllItems: true,
        available: true,
      }),
    },
  },
  data() {
    return {
      allOffers: [],
      offersLoading: false,
      selectedOffers: [],
      offersIds: {
        index: 0,
      },
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization', 'defaultLang']),
  },
  mounted() {
    this.offersLoading = true
    fetchOffersRequest(this.selectOffersParams).then(response => {
      const { offers } = response.data
      this.allOffers = offers
    })
      .finally(() => {
        this.offersLoading = false
      })
  },
  methods: {
    onSubmit() {
      const offersIds = this.selectedOffers
      const offersIdsObj = {
        offersIds,
        redirectUrl: `${window.location.origin}/registration/offers`,
      }
      patchOrganizationOfferRequest(this.$route.params.id, offersIdsObj)
        .then(() => {
          this.resetValidator()
          this.$emit('refetch-data')
          this.$emit('update:is-submit-offer-sidebar-active', false)
        })
        .catch(() => false)
    },
    validationForm() {
      this.$refs.submitOffers.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.submitOffers) {
          this.selectedOffers = []
        }
      })
    },
    filterOffers(options, search) {
      return options.filter(option => {
        const label = option.reference[this.defaultLang]

        return (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1
      })
    },
  },
}
</script>
