<template>
  <section>
    <!--HEADER-->
    <div class="d-flex justify-content-between align-items-center mb-1">
      <v-select
        v-model="params.numberOfItemsPerPage"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="perPageOptions"
        :clearable="false"
        class="per-page-selector d-inline-block mx-50"
      />
      <app-btn-add v-if="canEdit" size="md" @click=";(isAddNewAddressSidebarActive = true), (addressSelected = undefined)" />
    </div>

    <!--LIST-->
    <b-overlay :show="loadOrganizationAddresses" no-fade>
      <b-row>
        <b-col
          v-for="address in organizationAddress"
          :key="address.id"
          cols="12"
          md="6"
          lg="4"
          xl="3"
          class="d-flex align-items-stretch"
        >
          <b-card no-body :class="['w-100', { 'border-primary' : address.default }]">
            <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
              <h5>{{ address.address.addressName }}</h5>
              <b-badge v-show="address.default" variant="light-primary">
                {{ $t('address.default') }}
              </b-badge>
            </b-card-header>
            <b-card-body class="d-flex flex-column pb-50">
              <ul class="list-unstyled">
                <li>{{ address.address.address }}</li>
                <li>{{ address.address.complementaryAddress }}</li>
                <li>{{ address.address.postalCode }}</li>
                <li>{{ address.address.city }}</li>
                <li>{{ address.address.countryName }}</li>
              </ul>
              <div class="d-flex flex-wrap mt-auto">
                <b-button
                  v-if="canEdit"
                  variant="flat-primary"
                  class="d-flex"
                  @click=";(isAddNewAddressSidebarActive = true), (addressSelected = _cloneDeep(address))"
                >
                  <font-awesome-icon class="mr-lg-25" icon="edit" />
                  {{ $t('action.edit') }}
                </b-button>

                <b-button
                  v-if="!address.default && canEdit"
                  variant="flat-primary"
                  class="d-flex"
                  @click="confirmDeleteAlert(address.address.id)"
                >
                  <font-awesome-icon class="mr-lg-25" icon="trash" />
                  {{ $t('action.delete') }}
                </b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <!--PAGINATION-->
    <app-data-table-bottom
      table-name="addresses"
      class="px-2 mb-1"
      :page="params.page"
      :per-page="params.numberOfItemsPerPage"
      :total-count="totalOrganizationAddress"
      @pagination="params.page = $event"
    />

    <app-form-wizard-footer
      class="mt-3"
      previous
      next
      @click:previous="$emit('previous-tab')"
      @click:next="$emit('next-tab')"
    />

    <!--SIDEBAR-->
    <sidebar-address
      v-model="isAddNewAddressSidebarActive"
      :address="addressSelected"
      :organization-id="selectedOrganization.id"
      @refetch-data="refetchData"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'

import {
  deleteOrganizationAddressRequest,
  fetchOrganizationAdressesRequest,
} from '@/request/globalApi/requests/organizationRequests'
import SidebarAddress from '@/views/organization/sidebar/SidebarAddress.vue'
import AppBtnAdd from '@/components/AppBtnAdd.vue'
import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

export default {
  components: {
    AppBtnAdd,
    SidebarAddress,
    AppDataTableBottom,
    AppFormWizardFooter,
  },
  props: {
    organizationId: {
      type: [String, Number],
      required: true,
      default: null,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadOrganizationAddresses: false,
      isAddNewAddressSidebarActive: false,
      addressSelected: undefined,
      organizationAddress: null,
      totalOrganizationAddress: 0,
      params: {
        numberOfItemsPerPage: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    allFilters() {
      return `${this.params.numberOfItemsPerPage}|${this.params.page}`
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchOrganizationAddresses()
  },
  methods: {
    APIFetchOrganizationAddresses() {
      this.loadOrganizationAddresses = true
      fetchOrganizationAdressesRequest(this.organizationId, this.params).then(response => {
        const { addresses, totalItems } = response.data
        this.organizationAddress = addresses
        this.totalOrganizationAddress = totalItems
      }).finally(() => {
        this.loadOrganizationAddresses = false
      })
    },
    confirmDeleteAlert(addressId) {
      deleteOrganizationAddressRequest(this.selectedOrganization.id, addressId).then(isConfirmed => {
        isConfirmed && this.refetchData()
      })
    },
    refetchData() {
      this.APIFetchOrganizationAddresses()
    },
  },
}
</script>
