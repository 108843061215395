<template>
  <div>
    <b-alert variant="warning" show>
      <div class="alert-body">
        <feather-icon icon="AlertTriangleIcon" class="mr-50" />
        {{ $t('organization.legal_informations.changes_warning') }}
      </div>
    </b-alert>

    <b-row v-if="seller" class="my-2">
      <!-- KYC -->
      <b-col cols="12" md="6">
        <b-button
          :disabled="loadOrganizationKYCAccountLink || !canEdit"
          @click="redirectKycAccountLink()"
        >
          <b-spinner v-if="loadOrganizationKYCAccountLink" class="align-middle" small />
          {{ $t('organization.kyc.account_link') }}
        </b-button>
      </b-col>
    </b-row>

    <validation-observer ref="legalInformationsRules" tag="form" @submit.prevent="validationForm">
      <!-- Header: Legal Info -->
      <div class="d-flex">
        <h4 class="mb-1">{{ $t('organization.legal_informations.title') }}</h4>
      </div>

      <b-row>
        <!-- Field: Siret -->
        <b-col cols="12" md="6">
          <app-input
            id="ompanyRegistrationNumber"
            v-model="organizationLegal.siret"
            :label="$t('organization.legal_informations.company_registration_number')"
            :disabled="!canEdit"
            required
            rules="max:15"
          />
        </b-col>

        <!-- Field: APE -->
        <b-col cols="12" md="6">
          <app-input
            id="apeCode"
            v-model="organizationLegal.ape"
            :label="$t('organization.legal_informations.ape_code')"
            :disabled="!canEdit"
            required
            rules="max:15"
          />
        </b-col>

        <!-- Field: VAT -->
        <b-col cols="12" md="6">
          <app-input
            id="vatLegalInfo"
            v-model="organizationLegal.intracommunityVatNumber"
            :label="$t('organization.legal_informations.intra_community_vat')"
            :disabled="!canEdit"
            required
          />
        </b-col>

        <!-- Field: Social capital -->
        <b-col cols="12" md="6">
          <app-input
            id="socialCapital"
            v-model="organizationLegal.shareCapital"
            :label="$t('organization.legal_informations.share_capital')"
            :disabled="!canEdit"
            required
          />
        </b-col>

        <!-- Field: RCS -->
        <b-col cols="12" md="6">
          <app-input
            id="rcs"
            v-model="organizationLegal.rcs"
            :label="$t('organization.legal_informations.rcs')"
            :disabled="!canEdit"
            required
          />
        </b-col>

        <!-- Field: Legal form -->
        <b-col cols="12" md="6">
          <app-input
            id="rcs"
            v-model="organizationLegal.legalForm"
            :label="$t('organization.legal_informations.legal_form')"
            :disabled="!canEdit"
          />
        </b-col>
        <b-col cols="12" md="6">
          <app-datepicker
            id="dateCreated"
            v-model="organizationLegal.dateCreated"
            :label="$t('common.date_created')"
            :disabled="!canEdit"
          />
        </b-col>
      </b-row>

      <b-row v-if="seller" class="mb-3">
        <!-- CGV -->
        <b-col cols="12" md="6">
          <label label-for="cgv" class="mt-1">
            {{ $t('organization.legal_informations.cgv') }}
          </label>
          <div class="text-muted font-small-3">
            <span class="font-weight-bold">{{ $t('dropzone.accepted_format') }}:</span> <span>pdf</span> |
            <span class="font-weight-bold">{{ $t('dropzone.max_file_size') }}:</span> <span>2000Ko</span>
          </div>
          <app-drop-zone
            :disabled="!canEdit"
            id="organizationLegalCGV"
            ref="organizationLegalCGV"
            :value="{
              name: organizationLegal.cgvPath,
              fileUrl: organizationLegal.cgvUrl,
            }"
            class="d-flex justify-content-center align-content-center dropzone_small dropzoneIsPdf"
            :options="dropzoneCGV"
            @vdropzone-removed-file="removeFile(organizationLegal.cgvUrl, organizationLegal.cgvPath, organizationLegal, 'deleteOldCgvFile')"
            @vdropzone-file-added="sendingFile($event, organizationLegal, 'cgvFileId' , 'filesUploaded', 'deleteOldCgvFile', 'cgvUrl', 'organizationLegalCGV')"
          />
          <a v-if="organizationLegal.cgvUrl && !organizationLegal.deleteOldCgvFile && !organizationLegal.cgvFileId" target="_blank" :href="organizationLegal.cgvUrl">{{ organizationLegal.cgvPath }}</a>
        </b-col>
      </b-row>

      <app-form-wizard-footer
        :disabled="!canEdit"
        class="mt-3"
        save
        previous
        save-next
        @click:previous="$emit('previous-tab')"
        @click:save-next="nextRedirection = true"
      />

    </validation-observer>

  </div>
</template>

<script>
/* eslint-disable func-names */

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { mapState } from 'vuex'
import { pick } from 'lodash'

import imgUpload from '@/helpers/imgUpload'
import {
  fetchOrganizationKYCAccountLinkRequest,
  fetchOrganizationLegalRequest,
  patchOrganizationLegalRequest,
} from '@/request/globalApi/requests/organizationRequests'
import AppDropZone from '@/components/AppDropZone.vue'
import AppDatepicker from '@/components/AppDatepicker.vue'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'
import stripe from '@/libs/stripes'

export default {
  name: 'Legal',

  components: {
    AppDropZone,
    AppDatepicker,
    AppFormWizardFooter,
  },
  mixins: [imgUpload],
  props: {
    organizationData: {
      type: Object,
      default: () => ({}),
    },
    seller: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: [String, Number],
      required: true,
      default: null,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadOrganizationKYCAccountLink: false,
      filesUploaded: true,
      nextRedirection: false,
      organizationLegal: {
        siret: null,
        ape: null,
        extendedData: null,
        intracommunityVatNumber: null,
        legalForm: null,
        dateCreated: null,
        shareCapital: null,
        rcs: null,
        deleteOldCgvFile: false,
        cgvFileId: null,
      },
      dropzoneCGV: {
        url: 'https://localhost',
        dictDefaultMessage: this.$t('organization.legal.cgv.upload'),
        thumbnailWidth: 100,
        maxFilesize: 2000000,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: '.pdf',
        addRemoveLinks: true,
        uploadMultiple: false,
        useFontAwesome: true,
        init() {
          this.hiddenFileInput.removeAttribute('multiple')
          this.on('addedfile', function () {
            if (this.files.length > 1) {
              this.removeFile(this.files[0])
            }
          })
        },
      },
      dropzoneInvoiceMandate: {
        url: 'https://localhost',
        dictDefaultMessage: this.$t('organization.legal.invoice_mandate.upload'),
        thumbnailWidth: 100,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: '.pdf',
        addRemoveLinks: true,
        uploadMultiple: false,
        init() {
          this.hiddenFileInput.removeAttribute('multiple')
          this.on('addedfile', function () {
            if (this.files.length > 1) {
              this.removeFile(this.files[0])
            }
          })
        },
      },
    }
  },
  computed: { ...mapState('auth', ['selectedOrganization']) },
  watch: {
    filesUploaded(val) {
      this.$emit('onFilesUploaded', val)
    },
  },
  mounted() {
    fetchOrganizationLegalRequest(this.organizationId).then(response => {
      const { organization } = response.data
      this.organizationLegal = { ...organization, deleteOldCgvFile: false, cgvFileId: null }
    })
  },
  methods: {
    redirectKycAccountLink() {
      stripe.then(response => {
        this.stripe = response
        this.APIFetchOrganizationKYCAccountLink()
      })
    },
    APIFetchOrganizationKYCAccountLink() {
      this.loadOrganizationKYCAccountLink = true
      this.stripe
        .createToken('account', {
          business_type: 'company',
          account: {
            tos_shown_and_accepted: true,
          },
        })
        .then(result => {
          if (result.error || !result.token) {
            this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
          } else {
            const params = {
              refreshUrl: window.location.href,
              returnUrl: window.location.href,
              accountToken: result.token.id,
            }

            fetchOrganizationKYCAccountLinkRequest(this.organizationId, params).then(response => {
              window.open(response.data.url)
            }).finally(() => {
              this.loadOrganizationKYCAccountLink = false
            })
          }
        })
        .catch(() => false)
    },
    // Use it into AccountWizzard via ref
    async validationForm() {
      return this.$refs.legalInformationsRules.validate()
        .then(success => {
          if (success) {
            const configuration = {
              prefix: 'MFC',
              referenceFormat: 'date_six_numbers',
            }
            const organizationLegal = {
              ...pick(this.organizationLegal, 'siret', 'ape', 'intracommunityVatNumber', 'legalForm', 'dateCreated', 'shareCapital', 'rcs', 'cgvFileId'),
              extendedData: {
                ...this.organizationLegal.extendedData,
                quoteConfiguration: configuration,
                invoiceConfiguration: configuration,
              },
            }
            patchOrganizationLegalRequest(this.selectedOrganization.id, organizationLegal)
              .then(() => {
                delete this.organizationLegal.cgvFileId
                if (this.nextRedirection) {
                  this.$emit('next-tab')
                  this.nextRedirection = false
                }
                return true
              })
          }
          return false
        })
    },
  },
}
</script>

<style lang="scss">
.dropzone .dz-preview .dz-details .dz-size {
  display: none
}
</style>
