<template>
  <div>
    <validation-observer ref="mainInformationsRules" tag="form" @submit.prevent="validationForm">
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <h4 class="mb-1">{{ $t('organization.main_informations.title') }}</h4>
      </div>
      <b-row>
        <!-- Field: Legal name -->
        <b-col cols="12" lg="4" md="6">
          <app-input
            id="legalName"
            v-model="organizationInfos.legalName"
            :label="$t('organization.main_informations.legal_name')"
            required
            :disabled="!canEdit"
          />
        </b-col>

        <!-- Field: Commercial name -->
        <b-col cols="12" lg="4" md="6">
          <app-input
            id="commercialName"
            v-model="organizationInfos.commercialName"
            :label="$t('organization.main_informations.commercial_name')"
            :disabled="!canEdit"
          />
        </b-col>

        <!-- Field: Language -->
        <b-col cols="12" lg="4" md="6">
          <enum-select
            id="langCode"
            :value="organizationInfos.defaultLang ? organizationInfos.defaultLang.code : ''"
            :label="$t('organization.main_informations.default_lang')"
            enum-class="LangCode"
            text="text"
            @input="organizationInfos.defaultLang = { code: $event }"
            :disabled="!canEdit"
          />
        </b-col>

        <!-- Field: Currency -->
        <b-col v-if="$store.state.auth.selectedOrganization.seller || $store.state.auth.selectedOrganization.operator" cols="12" lg="4" md="6">
          <enum-select
            id="currencyCode"
            :value="organizationInfos.defaultCurrency ? organizationInfos.defaultCurrency.code : ''"
            :label="$t('organization.main_informations.default_currency')"
            enum-class="CurrencyCode"
            :clearable="false"
            disabled
          />
        </b-col>
        <b-col v-if="$store.state.auth.selectedOrganization.seller || $store.state.auth.selectedOrganization.operator" class="mb-2" cols="12">
          <span>{{ $t('common.note') }} {{ $t('organization.main_informations.default_currency_note') }}</span>
        </b-col>
      </b-row>
      <!-- Header: Head Office -->
      <div class="d-flex mt-2">
        <h4 class="mb-1">
          {{ $t('organization.main_informations.head_office') }}
        </h4>
      </div>
      <b-row>
        <!-- Field: Address -->
        <b-col cols="12" lg="4" md="6">
          <app-input
            id="address"
            v-model="organizationInfos.defaultAddress.address"
            :label="$t('common.address')"
            required
            :disabled="!canEdit"
          />
        </b-col>

        <!-- Field: Complementary Address -->
        <b-col cols="12" lg="4" md="6">
          <app-input
            id="complementaryAddress"
            v-model="organizationInfos.defaultAddress.complementaryAddress"
            :label="$t('address.complementary_address')"
            :disabled="!canEdit"
          />
        </b-col>

        <!-- Field: Zip code -->
        <b-col cols="12" lg="4" md="6">
          <app-input
            id="postalCode"
            v-model="organizationInfos.defaultAddress.postalCode"
            :label="$t('address.postal_code')"
            :disabled="!canEdit"
          />
        </b-col>

        <!-- Field: city -->
        <b-col cols="12" lg="4" md="6">
          <app-input
            id="city"
            v-model="organizationInfos.defaultAddress.city"
            :label="$t('address.city')"
            :disabled="!canEdit"
          />
        </b-col>

        <!-- Field: Country -->
        <b-col cols="12" lg="4" md="6">
          <enum-select
            :disabled="!canEdit" id="countryCode" v-model="organizationInfos.defaultAddress.countryCode" :label="$t('common.country')" enum-class="CountryCode" text="text" :clearable="false" required />
        </b-col>
      </b-row>

      <!-- Header: Emergency Contact -->
      <div class="d-flex mt-2">
        <h4 class="mb-1">
          {{ $t('organization.main_informations.emergency_contact') }}
        </h4>
      </div>
      <b-row>
        <!-- Field: Emergency First name  -->
        <b-col cols="12" lg="3" md="6">
          <validation-provider #default="{ errors }" :name="$t('organization.main_informations.contact_first_name')" class="validation-required" rules="required">
            <b-form-group :label="$t('organization.main_informations.contact_first_name')" label-for="emergencyFirstName">
              <b-form-input
                :disabled="!canEdit" id="emergencyFirstName" v-model="organizationInfos.emergencyContactFirstName" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: Emergency Last name -->
        <b-col cols="12" lg="3" md="6">
          <validation-provider #default="{ errors }" :name="$t('organization.main_informations.contact_last_name')" class="validation-required" rules="required">
            <b-form-group :label="$t('organization.main_informations.contact_last_name')" label-for="emergencyLastName">
              <b-form-input
                :disabled="!canEdit" id="emergencyLastName" v-model="organizationInfos.emergencyContactLastName" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: Emergency mail-->
        <b-col cols="12" lg="3" md="6">
          <validation-provider #default="{ errors }" :name="$t('organization.main_informations.contact_email')" class="validation-required" rules="email">
            <b-form-group :label="$t('organization.main_informations.contact_email')" label-for="emergencyEmail">
              <b-form-input
                :disabled="!canEdit" id="emergencyEmail" v-model="organizationInfos.emergencyContactEmail" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: Emergency phone-->
        <b-col cols="12" lg="3" md="6">
          <b-form-group :label="$t('organization.main_informations.contact_tel')" label-for="emergencyTel">
            <vue-tel-input
              v-model="organizationInfos.emergencyContactPhone"
              :class="phoneEmergencyObj && !phoneEmergencyObj.valid && organizationInfos.emergencyContactPhone ? 'is-invalid' : null"
              :default-country="organizationInfos.defaultLang.code"
              class="form-control"
              @validate="val => (phoneEmergencyObj = val)"
              :disabled="!canEdit"
            />
            <small v-if="phoneEmergencyObj && phoneEmergencyObj.valid === false && organizationInfos.emergencyContactPhone" class="text-danger">
              {{ $t('validator.invalid_tel.message') }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Accounting Contact -->
      <div class="d-flex mt-2">
        <h4 class="mb-1">
          {{ $t('organization.main_informations.accounting_contact') }}
        </h4>
      </div>
      <b-row>
        <!-- Field: Accounting first name-->
        <b-col cols="12" lg="3" md="6">
          <validation-provider #default="{ errors }" :name="$t('organization.main_informations.contact_first_name')">
            <b-form-group :label="$t('organization.main_informations.contact_first_name')" label-for="accountingFirstName">
              <b-form-input
                :disabled="!canEdit" id="accountingFirstName" v-model="organizationInfos.accountingContactFirstName" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: Accounting last name-->
        <b-col cols="12" lg="3" md="6">
          <validation-provider #default="{ errors }" :name="$t('organization.main_informations.contact_last_name')">
            <b-form-group :label="$t('organization.main_informations.contact_last_name')" label-for="accountingLastName">
              <b-form-input
                :disabled="!canEdit" id="accountingLastName" v-model="organizationInfos.accountingContactLastName" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: Accounting mail-->
        <b-col cols="12" lg="3" md="6">
          <validation-provider #default="{ errors }" :name="$t('organization.main_informations.contact_email')" rules="email">
            <b-form-group :label="$t('organization.main_informations.contact_email')" label-for="accountingNameEmail">
              <b-form-input
                :disabled="!canEdit" id="accountingNameEmail" v-model="organizationInfos.accountingContactEmail" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: Accounting phone-->
        <b-col cols="12" lg="3" md="6">
          <b-form-group :label="$t('organization.main_informations.contact_tel')" label-for="accountingNameTel">
            <vue-tel-input
              v-model="organizationInfos.accountingContactPhone"
              :class="phoneAccountingObj && !phoneAccountingObj.valid && organizationInfos.accountingContactPhone ? 'is-invalid' : null"
              :default-country="organizationInfos.defaultLang.code"
              class="form-control"
              @validate="val => (phoneAccountingObj = val)"
              :disabled="!canEdit"
            />
            <small v-if="phoneAccountingObj && phoneAccountingObj.valid === false && organizationInfos.accountingContactPhone" class="text-danger">
              {{ $t('validator.invalid_tel.message') }}
            </small>
          </b-form-group>
        </b-col>

        <!-- Field: Fees collector-->
        <b-col v-if="seller" cols="12">
          <validation-provider #default="{ errors }" :name="$t('organization.main_informations.fees_collector')" class="validation-required" rules="required">
            <b-form-group :label="$t('organization.main_informations.fees_collector')" :state="errors.length > 0 ? false : null" label-for="feesCollector">
              <b-form-checkbox
                :disabled="!canEdit" v-model="organizationInfos.feesCollector" inline name="check-button" switch />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <div v-if="$can('OPERATOR_ORGANIZATION_VIEW') && organizationId != selectedOrganization.id">
        <!-- Header: Operator parameters -->
        <div class="d-flex mt-2">
          <h4 class="mb-1">
            {{ $t('organization.main_informations.operator_parameters') }}
          </h4>
        </div>
        <b-row>
          <!-- Field: vat rate-->
          <b-col cols="12" md="6">
            <app-input
              id="vatRate"
              v-model.number="organizationInfos.vatRate"
              :label="$t('common.vat')"
              append="%"
              rules="required|positive"
              step="0.01"
              type="number"
            />
          </b-col>
          <!-- Field: vat rate-->
          <b-col cols="12" md="6">
            <app-input id="vatComment" v-model="organizationInfos.vatComment" :label="$t('pricing.vat_comment')"></app-input>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: vat rate-->
          <b-col cols="12" md="6">
            <enum-select
              v-model="organizationInfos.status"
              :label="$t('common.status')"
              enum-class="OrganizationStatus"
              required
            />
          </b-col>
        </b-row>
      </div>
      <app-form-wizard-footer
        :disabled="!canEdit" class="mt-3" save save-next @click:save-next="nextRedirection = true" />
    </validation-observer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { VueTelInput } from 'vue-tel-input'
import {
  fetchOrganizationInfosRequest,
  patchOrganizationInfosRequest,
} from '@/request/globalApi/requests/organizationRequests'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

export default {
  name: 'Information',

  components: {
    VueTelInput,
    AppFormWizardFooter,
  },
  props: {
    organizationData: {
      type: Object,
      default: () => ({}),
    },
    seller: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: [String, Number],
      required: true,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneEmergencyObj: null,
      phoneAccountingObj: null,
      nextRedirection: false,
      organizationInfos: {
        legalName: '',
        commercialName: null,
        defaultLang: {},
        defaultCurrency: {},
        defaultAddress: {},
        invoicePrefix: '',
        emergencyContactFirstName: '',
        emergencyContactLastName: '',
        emergencyContactEmail: '',
        emergencyContactPhone: null,
        accountingContactFirstName: null,
        accountingContactLastName: null,
        accountingContactEmail: null,
        accountingContactPhone: null,
        feesCollector: false,
        vatRate: 0.0,
        vatComment: null,
      },
    }
  },
  computed: { ...mapState('auth', ['user', 'selectedOrganization']) },
  mounted() {
    fetchOrganizationInfosRequest(this.organizationId).then(response => {
      const { organization } = response.data
      this.organizationInfos = organization
    })
  },
  methods: {
    validationForm() {
      return this.$refs.mainInformationsRules.validate().then(success => {
        if (success) {
          const organizationInfos = this.restructureOrganizationInfos()
          organizationInfos.extendedData.quoteConfiguration = {
            prefix: 'MFC',
            referenceFormat: 'date_six_numbers',
          }
          organizationInfos.extendedData.invoiceConfiguration = {
            prefix: 'MFC',
            referenceFormat: 'date_six_numbers',
          }

          if (
            (this.phoneEmergencyObj && this.phoneEmergencyObj.valid !== undefined && !this.phoneEmergencyObj.valid)
            || (this.phoneAccountingObj && this.phoneAccountingObj.valid !== undefined && !this.phoneAccountingObj.valid)
          ) {
            this.toaster(this.$t('validator.invalid_tel.message'), 'danger', 'AlertTriangleIcon')
            return false
          }
          if (this.phoneEmergencyObj?.nationalNumber) {
            organizationInfos.emergencyContactPhone = `+${this.phoneEmergencyObj.countryCallingCode}${this.phoneEmergencyObj.nationalNumber}`
          }
          if (this.phoneAccountingObj?.nationalNumber) {
            organizationInfos.accountingContactPhone = `+${this.phoneAccountingObj.countryCallingCode}${this.phoneAccountingObj.nationalNumber}`
          }

          return patchOrganizationInfosRequest(this.organizationId, organizationInfos)
            .then(() => {
              this.$store.commit('appConfig/UPDATE_CURRENCY', this.organizationInfos.defaultCurrency.code)
              this.$store.commit('auth/UPDATE_ORGANIZATION', { id: this.organizationId, ...this.organizationInfos })
              if (this.nextRedirection) {
                this.$emit('next-tab')
                this.nextRedirection = false
              }
              return true
            })
            .catch(() => false)
        }
        return false
      })
    },
    restructureOrganizationInfos() {
      const organizationInfos = JSON.parse(JSON.stringify(this.organizationInfos))
      organizationInfos.defaultCurrencyCode = organizationInfos.defaultCurrency?.code
      delete organizationInfos.defaultCurrency
      organizationInfos.defaultLangCode = organizationInfos.defaultLang.code
      delete organizationInfos.defaultLang
      organizationInfos.address = organizationInfos.defaultAddress
      delete organizationInfos.defaultAddress
      return organizationInfos
    },
  },
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
