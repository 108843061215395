<template>
  <b-row
    class="d-flex align-items-center border-light rounded"
  >
    <b-col
      cols="2"
      class="py-1 bg-light-secondary"
    >
      <p class="d-none d-sm-block">{{ $moment().isoWeekday(dayName).locale($i18n.locale).format('dddd') | capitalize }}</p>
      <p class="d-block d-sm-none">{{ $moment().isoWeekday(dayName).locale($i18n.locale).format('ddd') | capitalize }}</p>
    </b-col>
    <b-col
      cols="10"
      sm="5"
      class="align-items-center border-right"
    >
      <div class="d-flex">
        <b-form-checkbox
          :checked="workingDay.amStart !== null || workingDay.amEnd !== null"
          class="custom-control-primary"
          name="check-button"
          switch
          lazy
          :disabled="disabled"
          @input="e => !e ? (workingDay.amStart = null, workingDay.amEnd = null) : (!workingDay.amStart ? workingDay.amStart = 7 : true, !workingDay.amEnd ? workingDay.amEnd = 11 : true)"
        >
          <span class="switch-icon-left">
            <font-awesome-icon icon="store" />
          </span>
          <span class="switch-icon-right">
            <font-awesome-icon
              icon="store-slash"
            />
          </span>
        </b-form-checkbox>
        <span
          v-if="workingDay.amStart || workingDay.amEnd"
          class="d-flex align-items-center"
        >
          <div>
            <b-form-select
              :disabled="disabled"
              v-model="workingDay.amStart"
              size="sm"
            >
              <option
                v-for="option in amHours"
                :key="option"
                :value="option"
              >
                {{ option }}:00
              </option>
            </b-form-select>
          </div>
          <div class="mx-50 font-small-2">
            {{ $t('common.to') | capitalize }}
          </div>
          <div>
            <b-form-select
              :disabled="disabled"
              v-model="workingDay.amEnd"
              size="sm"
            >
              <option
                v-for="option in amHours"
                :key="option"
                :value="option"
              >
                {{ option }}:00
              </option>
            </b-form-select>
          </div>
        </span>
        <span v-else>
          <b-badge variant="light-secondary">
            {{ $t('state.close_in_morning') }}
          </b-badge>
        </span>
      </div>

      <div class="d-flex d-sm-none">
        <b-form-checkbox
          :disabled="disabled"
          :checked="workingDay.pmStart !== null || workingDay.pmEnd !== null"
          class="custom-control-primary"
          name="check-button"
          switch
          @input="e => !e ? (workingDay.pmStart = null, workingDay.pmEnd = null) : (!workingDay.pmStart ? workingDay.pmStart = 12 : true, !workingDay.pmEnd ? workingDay.pmEnd = 22 : true)"
        >
          <span class="switch-icon-left">
            <font-awesome-icon icon="store" />
          </span>
          <span class="switch-icon-right">
            <font-awesome-icon
              icon="store-slash"
            />
          </span>
        </b-form-checkbox>
        <span
          v-if="workingDay.pmStart || workingDay.pmEnd"
          class="d-flex align-items-center"
        >
          <div>
            <b-form-select
              :disabled="disabled"
              v-model="workingDay.pmStart"
              size="sm"
            >
              <option
                v-for="option in pmHours"
                :key="option"
                :value="option"
              >
                {{ option }}:00
              </option>
            </b-form-select>
          </div>
          <div class="mx-50 font-small-2">
            {{ $t('common.to') | capitalize }}
          </div>
          <div>
            <b-form-select
              :disabled="disabled"
              v-model="workingDay.pmEnd"
              size="sm"
            >
              <option
                v-for="option in pmHours"
                :key="option"
                :value="option"
              >
                {{ option }}:00
              </option>
            </b-form-select>
          </div>
        </span>
        <span v-else>
          <b-badge variant="light-secondary">
            {{ $t('state.close_after_noon') }}
          </b-badge>
        </span>
      </div>
    </b-col>

    <b-col
      sm="5"
      class="d-none d-sm-block align-items-center"
    >
      <div class="d-flex">
        <b-form-checkbox
          :disabled="disabled"
          :checked="workingDay.pmStart !== null || workingDay.pmEnd !== null"
          class="custom-control-primary"
          name="check-button"
          switch
          @input="e => !e ? (workingDay.pmStart = null, workingDay.pmEnd = null) : (!workingDay.pmStart ? workingDay.pmStart = 12 : true, !workingDay.pmEnd ? workingDay.pmEnd = 19 : true)"
        >
          <span class="switch-icon-left">
            <font-awesome-icon icon="store" />
          </span>
          <span class="switch-icon-right">
            <font-awesome-icon
              icon="store-slash"
            />
          </span>
        </b-form-checkbox>
        <span
          v-if="workingDay.pmStart || workingDay.pmEnd"
          class="d-flex align-items-center"
        >
          <div>
            <b-form-select
              :disabled="disabled"
              v-model="workingDay.pmStart"
              size="sm"
            >
              <option
                v-for="option in pmHours"
                :key="option"
                :value="option"
              >
                {{ option }}:00
              </option>
            </b-form-select>
          </div>
          <div class="mx-50 font-small-2">
            {{ $t('common.to') | capitalize }}
          </div>
          <div>
            <b-form-select
              :disabled="disabled"
              v-model="workingDay.pmEnd"
              size="sm"
            >
              <option
                v-for="option in pmHours"
                :key="option"
                :value="option"
              >
                {{ option }}:00
              </option>
            </b-form-select>
          </div>
        </span>
        <span v-else>
          <b-badge variant="light-secondary">
            {{ $t('state.close_after_noon') }}
          </b-badge>
        </span>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'WorkingDay',

  props: {
    workingDay: {
      type: Object,
      default: () => ({}),
    },
    dayName: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      amHours: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      pmHours: ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '0'],
    }
  },
}
</script>

<style scoped>

</style>
