<template>
  <b-sidebar
    id="sidebar-edit-invoice-configuration"
    :visible="isEditInvoiceConfigurationSidebarActive"
    no-header
    @change="val => $emit('update:is-edit-invoice-configuration-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('organization.accounting.invoice_configuration.side_bar.title') }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer ref="editInvoiceConfiguration" #default="{ handleSubmit }">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
          <!--Partie address-->
          <h5 class="mb-1 mt-1">
            {{ $t('organization.accounting.invoice_configuration.side_bar.address.title') }}
          </h5>
          <!-- Address: address -->
          <app-input
            id="invoiceConfigurationAddressMain"
            :disabled="!$can('ACCOUNTING_EDIT')"
            v-model="invoiceConfigurationData.address.address"
            :label="$t('common.address')"
            required
          />

          <!-- Address : complementary -->
          <app-input
            id="invoiceConfigurationAddressComplementary"
            :disabled="!$can('ACCOUNTING_EDIT')"
            v-model="invoiceConfigurationData.address.complementaryAddress"
            :label="$t('address.complementary_address')"
          />

          <b-row>
            <b-col cols="6">
              <!-- Address: postalCode -->
              <app-input
                id="invoiceConfigurationAddressPostalCode"
                :disabled="!$can('ACCOUNTING_EDIT')"
                v-model="invoiceConfigurationData.address.postalCode"
                :label="$t('address.postal_code')"
                required
              />
            </b-col>

            <b-col cols="6">
              <!-- Address: city -->
              <app-input
                id="invoiceConfigurationAddressCity"
                :disabled="!$can('ACCOUNTING_EDIT')"
                v-model="invoiceConfigurationData.address.city"
                :label="$t('address.city')"
                required
              />
            </b-col>
          </b-row>

          <!-- Address: country -->
          <enum-select
            id="invoiceConfigurationAddressCountry"
            :disabled="!$can('ACCOUNTING_EDIT')"
            v-model="invoiceConfigurationData.address.countryCode"
            :label="$t('common.country')"
            enum-class="CountryCode"
            :overlay="invoiceConfigurationDataLoading"
            :clearable="false"
            text="text"
            required
          />

          <div v-if="seller || operator">
            <!-- Partie invoice format -->
            <h5 class="mb-1 mt-1">
              {{ $t('organization.accounting.invoice_configuration.side_bar.format.title') }}
            </h5>
            <b-row>
              <b-col class="col-12" md="6">
                <!-- Format: prefix -->
                <app-input
                  id="invoiceConfigurationPrefix"
                  :disabled="!$can('ACCOUNTING_EDIT')"
                  v-model="invoiceConfigurationData.prefix"
                  :label="$t('organization.accounting.invoice_configuration.side_bar.format.prefix')"
                  required
                />
              </b-col>
              <b-col class="col-12" md="6">
                <!-- format: format -->
                <enum-select
                  id="invoiceConfigurationFormat"
                  :disabled="!$can('ACCOUNTING_EDIT')"
                  v-model="invoiceConfigurationData.referenceFormat"
                  :label="$t('organization.accounting.invoice_configuration.side_bar.format.format')"
                  enum-class="InvoiceReferenceFormat"
                  :overlay="invoiceConfigurationDataLoading"
                  :clearable="false"
                  required
                />
              </b-col>
            </b-row>

            <!-- Partie invoice footer -->
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.accounting.invoice_configuration.side_bar.footer')"
              class="validation-required"
              rules="required"
            >
              <b-form-group :label="$t('organization.accounting.invoice_configuration.side_bar.footer')" label-for="invoiceConfigurationFooter">
                <b-form-textarea :disabled="!$can('ACCOUNTING_EDIT')" id="invoiceConfigurationFooter" v-model="invoiceConfigurationData.footer" :state="errors.length > 0 ? false : null" rows="5" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>

          <div v-if="seller">
            <!-- Partie invoice composition -->
            <h5 class="mb-1 mt-1">
              {{ $t('organization.accounting.invoice_configuration.side_bar.composition') }}
            </h5>

            <enum-checkbox
              id="invoiceComposition"
              :value="compositionItems"
              :disabled="!$can('ACCOUNTING_EDIT')"
              enum-class="InvoiceCompositionItemType"
              label=""
              text="text"
              text-field="text"
              value-field="value"
              @change="compositionItems = $event"
            />
          </div>

          <!--Footer-->
          <div class="d-flex px-2 pt-2 mt-2 justify-content-end">
            <b-button v-if="$can('ACCOUNTING_EDIT')" v-ripple.400 :disabled="invoiceConfigurationDataLoading" class="mr-2" type="submit" @click.prevent="submitInvoiceConfiguration()">
              <font-awesome-icon class="mr-50" icon="user-plus" />
              {{ $t('action.save') }}
            </b-button>
            <b-button v-ripple.400 type="button" variant="outline-secondary" @click="hide">
              <font-awesome-icon class="mr-50" icon="ban" />
              {{ $t('action.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EnumCheckbox from '@/components/EnumCheckbox.vue'
import {
  editOrganizationInvoiceConfigurationRequest,
  fetchOrganizationInvoiceConfigurationRequest,
} from '@/request/globalApi/requests/organizationRequests'
import InvoiceConfiguration from '@/models/InvoiceConfiguration'

export default {
  name: 'SidebarInvoiceConfiguration',

  components: {
    EnumCheckbox,
  },
  props: {
    isEditInvoiceConfigurationSidebarActive: {
      type: Boolean,
    },
    organizationId: {
      type: Number,
      default: null,
    },
    seller: {
      type: Boolean,
      default: false,
    },
    operator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      invoiceConfigurationData: new InvoiceConfiguration(),
      invoiceConfigurationDataLoading: true,
      compositionItems: [],
    }
  },
  mounted() {
    this.fetchInvoiceConfiguration()
  },
  methods: {
    fetchInvoiceConfiguration() {
      this.invoiceConfigurationDataLoading = true
      fetchOrganizationInvoiceConfigurationRequest(this.organizationId)
        .then(response => {
          const invoiceConfiguration = response.data
          this.invoiceConfigurationData = new InvoiceConfiguration(invoiceConfiguration)
          this.compositionItems = this.invoiceConfigurationData.compositionItems[0].map(item => item.invoiceCompositionItemType)
        })
        .finally(() => {
          this.invoiceConfigurationDataLoading = false
        })
    },

    // SUBMIT FORM
    submitInvoiceConfiguration() {
      this.$refs.editInvoiceConfiguration.validate().then(success => {
        if (success) {
          this.invoiceConfigurationData.compositionItems = this.compositionItems.map(item => ({ invoiceCompositionItemType: item }))
          editOrganizationInvoiceConfigurationRequest(this.organizationId, this.invoiceConfigurationData, '').then(() => {
            this.resetValidator()
            this.$emit('update:is-edit-invoice-configuration-sidebar-active', false)
          })
        }
      })
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.addAddressRules) {
          this.$refs.addAddressRules.reset()
        }
      })
    },
  },
}
</script>

<style>
.invoiceConfigurationSelectInput label {
  font-size: 0.857rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
